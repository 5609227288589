import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString from 'querystring';
import * as _ from 'lodash';
import LoadingScreen from '../components/LoadingScreen';
import LOGO from '../img/logo.svg';
import ENROLLEE_ICON from '../img/single_guy_icon.svg';
import CLIENT_ICON from '../img/business_icon.svg';
import AFFILIATE_ICON from '../img/affiliate-icon.svg';
import PARTNERSHIP_ICON from '../img/extension_icon.svg';
import PROVIDER_ICON from '../provider_dash_icon.svg';
import ACCOUNTS_ICON from '../account_dash_icon.svg';
import RHMO_ICON from '../rhmo_icon.png';
import { getSubDomain, isLocal } from '../services/utilityService';



class WelcomeContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src: queryString.parse(props.location.search.substr(1)).src
    }
  }

  goTo(url) {
    window.location.replace(url);
  }

  render() {
    const {user, checked, authenticated, history} = this.props;

    if (checked && authenticated && !_.isEmpty(user)) {

      const accessibleRoles = user.roles.filter(role => role.can_access);
      
      // Redirect to SME dashboard if with SME, Company or Client credentials
        accessibleRoles.map(role => {
          if ( role.name === 'client' && role.can_access === true  ) {
            window.location.replace(getDestinationUrl(role)); 
          }
          return null;
        })
        

      if (!this.state.src) {
        //get Accessible roles
        if (accessibleRoles.length === 1) {
          //if role is accounts, go to /dashboard, else go to whatever url was given
          if (accessibleRoles[0].name === 'user') {
            this.goTo('/dashboard/enrollees');
            return null;
          } else {
            window.location.replace(getDestinationUrl(accessibleRoles[0]));
            return null;
          }
        }
      } else {
        if (this.state.src.toLowerCase() === "enrollee") {
          history.push("/dashboard/enrollees");
          return null;
        }
      }

      return (
        <div className="dashboard" style={{height: "100%"}}>
            <div className="select-account-wrap ">
                <img src={LOGO} alt="RelianceHMO's Logo" />
                <div style={{margin: "5rem 0"}}>
                    <h1 className="web-header__text">Welcome!</h1>
                    <p className="web-header__text_small">
                        You have access to these dashboards,<br />
                        Please select the account you want to make use of
                    </p>
                </div>

                <div className="select-account-card">
                  {accessibleRoles.map(role => (
                      <div key={role.name} className="dashboard-card" onClick={() => {
                        this.goTo(getDestinationUrl(role))
                      }}>
                        <img src={getDestinationIcon(role.name)} alt={role.name}/>
                        <p className="dashboard-card__body-text">{role.display_name}</p>
                      </div>
                  ))}
                </div>
            </div>
        </div>
      )
    }

    return (
      <div className="container">
        <div className="col-flex welcome-wrapper">
          <LoadingScreen/>
        </div>
      </div>
    )
  }
}

function getDestinationIcon(name) {
  switch (name) {
    case 'user':
      return ACCOUNTS_ICON;
    case 'enrollee':
      return ENROLLEE_ICON;
    case 'client':
      return CLIENT_ICON;
    case 'affliate':
      return AFFILIATE_ICON
    case 'partnership':
        return PARTNERSHIP_ICON
    case 'provider':
      return PROVIDER_ICON;
    default:
      return RHMO_ICON
  }
}

function getDestinationUrl({name, url}) {
  if (isLocal()) {
    switch (name) {
      case 'user':
        return "http://localhost:3000/";
      case 'enrollee':
        return "http://localhost:3001/";
      case 'client':
        return "http://localhost:3002/";
      case 'provider':
      default:
        return url;
    }
  } else {
    switch (name) {
      case 'user':
        return `https://accounts.${getSubDomain()}reliancehealth.com.eg`;
      case 'enrollee':
        return `https://dashboard.${getSubDomain()}reliancehealth.com.eg`;
      case 'client':
        return `https://client.${getSubDomain()}reliancehealth.com.eg`;
      default:
        return url;
    }
  }
}

function mapStateToProps(state) {
  const {checked, authenticated, user} = state.session || {};
  return {user, checked, authenticated};
}

export default withRouter(connect(mapStateToProps)(WelcomeContainer));