import React from 'react';
import NotificationBanner from '../components/boxes/NotificationBanner';
import * as enrolleeActions from '../actions/enrollee';
import * as invoiceActions from '../actions/invoice';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import EnrolleeListComponent from '../components/lists/EnrolleeListComponent';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import InvoiceListModal from "../components/modals/InvoiceListModal";
import OptionsModal from "../components/modals/OptionsModal";
import userService from "../services/userService"
import ApprovedPay from '../img/approved-pay.svg'

class EnrolleeListContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openInvoiceModal: false,
      viewEnrollee: false,
      enrolleeList: [],
      invoiceList: [],
      showInvoiceList: false,
      error: null,
      openInvoiceListModal: false,
      disabled:  true,
      disabledPay:  true,
      onChangeInvoice: '',
      expiredInvoices: [],
      enrollees: [],
      successfulPayment: false,
      selectedEnrolleInvoice: [],
      message: null, // To trigger nnotification banner
      status:null, // To trigger nnotification banner
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(enrolleeActions.getUserEnrollees());
    dispatch(invoiceActions.getUserInvoices());

    userService.renewableExpiredInvoices()
    .then(expiredInvoices => {
      this.setState({ expiredInvoices });
    }, (e=>{
      this.setState({
        status:"error",
        message: e.message,
      })
    }));
  }

  renderLoading() {
    return (
      <MainContent>
        <PageHeader title="" />
        <PageBody>
          <LoadingScreen />
        </PageBody>
      </MainContent>
    )
  }

  togglePaymentSuccessfulModal = () => {
    this.setState({
      successfulPayment: !this.state.successfulPayment
    })
  }

  onRemoveEnrollee = () => {
    const { dispatch, match, history } = this.props;
    dispatch(enrolleeActions.removeUserEnrollee(match.params.enrolleeId, history));
    this.toggleRemoveEnrolleeExtensionModal();
  };

  closeEnrolleeList = () => {
    this.setState({
      openInvoiceListModal: false,
      openInvoiceModal: !this.state.openInvoiceModal,
      disabled: true,
      disabledPay: true
    })
  }

  toggleInvoiceModal = () => {
    this.setState({
      openInvoiceModal: !this.state.openInvoiceModal,
    })
  }

  closeInvoiceListModal = () => {
    this.setState({
      openInvoiceModal: !this.state.openInvoiceModal,
      disabled: true
    })
  }

  toggleEnrolleeInvoiceModal = () => {
    this.setState({
      openInvoiceListModal: !this.state.openInvoiceListModal,
    })
  }

  toggleShowInvoiceList = () => {
    this.setState({
      showInvoiceList: !this.state.showInvoiceList
    })
  };

  onAddEnrollee = () => {
    const { invoiceList, history } = this.props;
    if (invoiceList.length > 1) {
      this.toggleShowInvoiceList();
    } else {
      const invoice = invoiceList[0];
      history.push(`/dashboard/invoices/edit/add-enrollee?refCode=${invoice.reference_code}`);
    }
  };

  onBuyNewPlan = () => {
    this.props.history.push("/dashboard/invoices/edit/new-invoice");
  };

  selectExpiredInvoice = (e) => {
    this.setState({
      onChangeInvoice: e.currentTarget.value,
      disabled: false
    })
  }

  proceedToInvoice = () => {
    const { onChangeInvoice } = this.state;
    userService.getInvoiceEnrollees(onChangeInvoice)
      .then(enrollees => {
        this.setState({ enrollees });
      })
      .catch(e=>{
        this.setState({
          status:"error",
          message: e.message,
        })
      })
    this.setState({
      openInvoiceListModal: !this.state.openInvoiceListModal,
      openInvoiceModal: false,
    })
  }

  // Close Notification banner
  onDismiss = () => {
    this.setState({
      status: ""
    })
  }

  addToRenew = (e) => {
    const {selectedEnrolleInvoice} =  this.state
    let selected = selectedEnrolleInvoice;

    if (e.currentTarget.checked) {
      selected.push(e.currentTarget.value)
    } else {
      selected = selected.filter(removeSelected => removeSelected !== e.currentTarget.value)
    }
    this.setState({
      selectedEnrolleInvoice: selected,
      disabledPay: selected.length < 1
    })
  }


  renewSuspendedPlans = () => {
    const { onChangeInvoice, selectedEnrolleInvoice} = this.state;

    this.setState({
      isLoading: true
    });
    userService.renewSuspendedPlans(onChangeInvoice, selectedEnrolleInvoice)
      .then(data => {
        this.setState({
          notification: null
        });
        window.location.href = data.authorization_url;
      })
      .catch(e => {
        this.setState({
          isLoading: false,
          notification: {type: "error", message: e.response.data.message}
        })
      })
  }

  render() {

    const {isFetching, invoiceList, enrolleeList, suspendedPlans} = this.props;
    const {showInvoiceList, error, openInvoiceModal, 
      openInvoiceListModal, disabled, expiredInvoices, enrollees, 
      disabledPay, onChangeInvoice, successfulPayment, status, message} = this.state;

      const listExpired = invoiceList.map((list) => list.active_status_id)[0]

    if (isFetching) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title="Enrollee List" />
        <PageBody>
          {status && (
            <NotificationBanner
              title={status === "error" ? "Error!" : "Notification!"}
              message={message}
              onDismiss={this.onDismiss}
              type={status === "error" ? "error" : "success"}
            />
          )}

          <InvoiceListModal
            invoices={invoiceList}
            onClose={this.toggleShowInvoiceList}
            open={showInvoiceList}
          />

          {error && (
            <NotificationBanner
              message={error.message}
              title="Error"
              type="error"
            />
          )}

          {/* Renew invoice modal */}
          <OptionsModal
            title={`Please select the invoice you want to renew. You can only renew 1 invoice at a time`}
            onYes={this.onRemoveEnrollee}
            onClose={this.closeInvoiceListModal}
            isVisible={openInvoiceModal && !isFetching}
          >
            {expiredInvoices.length < 0 && <h4>No Expired Invoice</h4>}
            <div className="invoice-options">
              <h5>Invoice details</h5>
              <form>
                <div className="invoice-options-list">
                  {expiredInvoices.map((expiredInvoice) => (
                    <div className="radio">
                      <input
                        id={expiredInvoice.reference_code}
                        name="radio"
                        value={expiredInvoice.reference_code}
                        onChange={this.selectExpiredInvoice}
                        type="radio"
                      />
                      <label
                        htmlFor={expiredInvoice.reference_code}
                        className="radio-label"
                      >
                        {expiredInvoice.reference_code}
                        <div className="plans-monthly">
                          <p>
                            {`${expiredInvoice.plan_count} plan(s)
                       ${
                         expiredInvoice.payment_frequency_id === 3
                           ? "Monthly"
                           : expiredInvoice.payment_frequency_id === 4
                           ? "Yearly"
                           : expiredInvoice.payment_frequency_id === 7
                           ? "Quaterly"
                           : "Not Avaliable"
                       }`}{" "}
                          </p>
                          <p>{`₦${expiredInvoice.total_price}`}</p>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  disabled={disabled}
                  onClick={this.proceedToInvoice}
                >
                  Proceed
                </button>
              </form>
            </div>
          </OptionsModal>

          {/* List of expired invoices */}
          <OptionsModal
            title={`Select all the accounts you want to renew on this invoice`}
            onYes={this.closeEnrolleeList}
            onClose={this.closeEnrolleeList}
            isVisible={openInvoiceListModal && !isFetching}
          >
            <div className="enrollee-list-content">
              <h2>{onChangeInvoice}</h2>
              <form>
                {isFetching && <LoadingScreen />}

                {enrollees.map((listEnrollees) => (
                  <>
                    <div className="checkbox-wrapper">
                      <input
                        className="checkbox"
                        onChange={this.addToRenew}
                        type="checkbox"
                        id={listEnrollees.user_id}
                        value={listEnrollees.user_id}
                      />
                      <label className="label" htmlFor={listEnrollees.user_id}>
                        <div className="checkbox-wrapper-display">
                          <p>{`${listEnrollees.first_name} ${listEnrollees.last_name}`}</p>
                          <p>{`₦${listEnrollees.product.price}`}</p>
                        </div>
                        <p>{listEnrollees.product.name}</p>
                        <p>{listEnrollees.product.package}</p>
                      </label>
                    </div>
                  </>
                ))}
                <button
                  disabled={disabledPay}
                  onClick={this.renewSuspendedPlans}
                  type="button"
                >
                  Pay
                </button>
              </form>
            </div>
          </OptionsModal>

          {/* successful payment */}
          <OptionsModal
            title={`Payment Successful!`}
            onYes={this.onRemoveEnrollee}
            onClose={this.togglePaymentSuccessfulModal}
            isVisible={successfulPayment && !isFetching}
          >
            <div className="for-deactivate">
              <img src={ApprovedPay} alt="" />
              <p>
                Your plan has been renewed and your expiry date has been
                extended. You can continue enjoying your health plan
              </p>
              <button type="button" onClick={this.togglePaymentSuccessfulModal}>
                Okay
              </button>
            </div>
          </OptionsModal>

          {enrolleeList && enrolleeList.length > 0 ? (
            <>
              <div className="dashboard-btn-section">
                <div>
                  {console.log(invoiceList)}
                  <p>
                    Hi there! You have{" "}
                    {`${invoiceList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.no_of_available_slots),
                      0
                    )} unassigned plan(s)`}
                  </p>
                  <p>{`${
                    invoiceList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.no_of_available_slots),
                      0
                    ) > 0
                      ? `Please click on the “Assign plan” button to 
                  assign the plan to your family/friend.`
                      : `Please click the “Buy new plan” button to add someone 
                   who doesn't have an existing plan`
                  }  To renew your existing plan, please click the renew button. Do not buy a new plan`}</p>
                </div>
                <div>
                  {suspendedPlans.length > 1 && (
                    <button
                      className="dashboard_custom_renew_btn dashboard-control__button"
                      onClick={this.toggleInvoiceModal}
                    >
                      {" "}
                      Renew Plan
                    </button>
                  )}
                  {invoiceList.reduce(
                    (total, currentValue) =>
                      (total = total + currentValue.no_of_available_slots),
                    0
                  ) > 0 ? (
                    <button
                      className="dashboard__primary-btn dashboard-control__button"
                      onClick={this.onAddEnrollee}
                    >
                      {" "}
                      Assign Plan
                    </button>
                  ) : (
                    <button
                      className="dashboard_renew_btn dashboard-control__button"
                      onClick={this.onBuyNewPlan}
                    >
                      {" "}
                      Buy New Plan
                    </button>
                  )}
                  {expiredInvoices.length > 0 && (
                    <button
                      className="dashboard_renew_btn dashboard-control__button"
                      onClick={this.onBuyNewPlan}
                    >
                      {" "}
                      Buy New Plan
                    </button>
                  )}
                </div>
              </div>

              <EnrolleeListComponent enrolleeList={enrolleeList} />
            </>
          ) : (
            <div
              className="dashboard-btn-section"
              style={{ justifyContent: "space-between" }}
            >
              {invoiceList && invoiceList.length === 0 && (
                <>
                  <div>
                    <i
                      className="fa fa-cart-plus fa-3x"
                      style={{ marginTop: "0.5rem", color: "#094063" }}
                    />
                  </div>

                  <p
                    className="dashboard-card__body-text"
                    style={{ alignSelf: "center", marginBottom: 0 }}
                  >
                    Click the button on the right to buy new Health Plans.
                  </p>

                  <button
                    className="dashboard__primary-btn dashboard-control__button"
                    onClick={this.onBuyNewPlan}
                  >
                    {" "}
                    Buy New Plan
                  </button>
                </>
              )}
              {invoiceList && invoiceList.length > 0 && (
                <>
                  <div>
                    <i
                      className="fa fa-user-plus fa-3x"
                      style={{ marginTop: "0.5rem", color: "#094063" }}
                    />
                  </div>

                  <p
                    className="dashboard-card__body-text"
                    style={{ alignSelf: "center", marginBottom: 0 }}
                  >
                    Click the button on the right to add an Enrollee to one of
                    your plans.
                  </p>

                  <div>
                    <button
                      className="dashboard__primary-btn dashboard-control__button"
                      onClick={this.onAddEnrollee}
                    >
                      {" "}
                      Assign Plan
                    </button>
                    {listExpired === 5 && (
                      <button
                        className="dashboard_renew_btn dashboard-control__button"
                        onClick={this.onBuyNewPlan}
                      >
                        {" "}
                        Buy New Plan
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </PageBody>
      </MainContent>
    );
  }
}


const mapStateToProps = ({ enrollees, invoice }) => ({
  isFetching: enrollees.isFetching || invoice.isFetching,
  enrolleeList: enrollees.allEnrollees,
  invoiceList: invoice.invoices ? invoice.invoices.filter(i => i.no_of_available_slots > 0) : [],
  suspendedPlans: invoice.invoices ? invoice.invoices.filter(plan => plan.active_status_id === 4) : [],
  error: enrollees.error,
  alert: enrollees.alert,
});

export default withRouter(connect(mapStateToProps)(EnrolleeListContainer));