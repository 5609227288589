import React from 'react';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';

const InvoiceModalListComponent = ({invoices}) => {

    return (
        invoices.length > 0 ? (
            <table className="dashboard-table">
                <thead>
                    <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Invoice Code</th>
                        <th className='dashboard-table-item dashboard-table-item__header'>Available Slots</th>
                        <th className="dashboard-table-item dashboard-table-item__header">Amount (NGN)</th>
                        <th className="dashboard-table-item dashboard-table-item__header">Date Issued</th>
                    </tr>
                </thead>
                <tbody>
                {invoices.map((invoice)=>(
                    <tr key={invoice.reference_code}>
                        <td className="dashboard-table-item dashboard-table-item__body">
                            {invoice.no_of_available_slots > 0 ? (
                                <a
                                  className="dashboard-table-item__link-item--underlined"
                                  href={`/dashboard/invoices/edit/add-enrollee?refCode=${invoice.reference_code}`}>
                                  {invoice.reference_code}
                                </a>) : (
                                invoice.reference_code
                            )
                            }
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">{invoice.no_of_available_slots}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",invoice.total_price)}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.issue_time).format("dddd, MMMM Do YYYY")}</td>
                    </tr>)
                )}
                </tbody>
            </table>
        ) : (
            <p style={{fontSize:"1.6rem",maxWidth:"500px",lineHeight:"2.4rem"}}>
                You have used up all the available employee slots in your invoice.
                To add more employee's, please contact support at <a href="mailto:hello@reliancehmo.com?subject=New Invoice">hello@reliancehmo.com</a>,
                so a new invoice can be generated for you. Thank You.
            </p>
        )

    )

};

InvoiceModalListComponent.propTypes = {
    invoices: PropTypes.array
};

export default InvoiceModalListComponent;