import axios from 'axios';
import Cookies from 'js-cookie';
import { states, occupations, maritalStatuses } from './constants';

export const isLocal = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const getEnrolleeDashboardUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:3001';
        case 'v3test':
        case 'development':
            return 'https://dashboard.develop.reliancehealth.com.eg';
        case 'staging':
            return 'https://dashboard.staging.reliancehealth.com.eg';
        case 'daara':
            return 'https://dashboard.daara.develop.reliancehealth.com.eg';
        case 'daara-staging':
            return 'https://dashboard.daara.staging.reliancehealth.com.eg';
        default:
            return 'https://dashboard.reliancehealth.com.eg';
    }
};

export const getWebsiteUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:4000';
        case 'v3test':
        case 'development':
            return 'https://develop.reliancehmo.com';
        case 'staging':
            return 'https://staging.reliancehmo.com';
        case 'daara':
            return 'https://daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://daara.staging.reliancehmo.com';
        default:
            return 'https://reliancehmo.com';
    }
};

export const getSubDomain = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'v3test':
        case 'development':
            return 'develop.';
        case 'staging':
            return 'staging.';
        case 'daara':
            return 'daara.develop.';
        case 'daara-staging':
            return 'daara.staging.';
        default:
            return '';
    }
};

const RELIANCE_API_VERSION = process.env.REACT_APP_RELIANCE_API_VERSION;

export const BASE_URL_RELIANCE = `${process.env.REACT_APP_BASE_API_URL}${RELIANCE_API_VERSION}`;

export const DOMAIN_COOKIE_NAME = isProduction() ? 'rat' : 'rat_' + process.env.REACT_APP_ENV;

export const DAARA_COOKIE_NAME = 'DAARA';

export const DAARA_TOKEN_PREFIX = process.env.REACT_APP_DAARA_TOKEN_PREFIX || 'ACCOUNT';

export const decodeResourceStatus = (statusCode) => {
    switch (statusCode) {
        case 1:
            return 'active';
        case 2:
            return 'pending';
        case 3:
            return 'deleted';
        case 4:
            return 'suspended';
        case 5:
            return 'expired';
        case 6:
            return 'deactivated';
        case 7:
            return 'delayed';
        default:
            return '';
    }
};

export const decodePaymentFrequency = (freqCode) => {
    switch (freqCode) {
        case 1:
            return 'Daily';
        case 2:
            return 'Weekly';
        case 3:
            return 'Monthly';
        case 4:
            return 'Yearly';
        case 5:
            return 'Biennially';
        case 6:
            return 'Endlessly';
        case 7:
            return 'Quarterly';
        default:
            return '';
    }
};

export const getDiscountedPrice = (price, discount) => {
    return price - (discount / 100) * price;
};

export const setCookie = (name, value, exDays, domain, path, sameSite, secure) => {
    if (!domain) domain = window.location.hostname;
    if (!path) path = '/';

    const cookieHolder = Cookies.set(name, value, {
        path,
        domain,
        expires: exDays,
        sameSite: sameSite,
        secure: secure
    });
};

export const getCookie = (_name) => {
    return Cookies.get(_name);
};

export const deleteCookie = (name, domain) => {
    Cookies.remove(name, { domain });
};

class UtilityService {
    getOccupations() {
        return Promise.resolve(occupations);
    }

    getStates() {
        return Promise.resolve(states);
    }

    getMaritalStatuses() {
        return Promise.resolve(maritalStatuses);
    }

    getUtilities() {
        return Promise.all([
            this.getOccupations(),
            this.getStates(),
            this.getMaritalStatuses()
        ]).then((responses) => {
            return {
                occupations: responses[0].data,
                states: responses[1].data,
                mStatuses: responses[2].data
            };
        });
    }

    getPlans(type) {
        return axios
            .get(`${BASE_URL_RELIANCE}/products${type ? `?type=${type}` : ''}`)
            .then((response) => {
                return response.data.data;
            });
    }

    getPlanById(id, PLANS) {
        if (!PLANS) {
            this.getPlans().then((plans) => this.getPlanById(id, plans));
        } else {
            return PLANS.filter((plan) => plan.id === id)[0];
        }
    }

    getProviders(productId) {
        return axios
            .get(`${BASE_URL_RELIANCE}/providers?product_id=${productId}`)
            .then((response) => {
                return response.data.data;
            });
    }

    getBanks() {
        return axios.get(`${BASE_URL_RELIANCE}/utility/banks`).then((response) => {
            return response.data.data;
        });
    }

    uploadFile(accessToken, file, uploadType) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_use', uploadType);
        return axios
            .post(`${BASE_URL_RELIANCE}/upload`, formData, {
                header: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.filename;
            })
            .catch((e) => {
                return {
                    message: e.response.data.message || 'An Error Occurred',
                    code: e.response.status
                };
            });
    }
}

export default new UtilityService();
