import React from 'react';
import queryString from 'querystring';
import { Redirect } from 'react-router';
import userService from '../services/userService';
import FormHolder, {MessageHolder} from "../components/boxes/FormHolder";
import VerifyOTPForm from "../components/forms/VerifyOTPForm";
import LoadingScreen from "../components/LoadingScreen";
import {connect} from 'react-redux';

class ResetPasswordVerify extends React.Component {
  constructor(props) {
    super(props)
    const token = queryString.parse(props.location.search.substr(1)).token;
    this.state = {
      token,
      error: null,
      success: null,
      loading: token ? true : false,
    }
  }

  componentDidMount() {
    const { token } = this.state;
    if (token) {
      this.verifyCode({code: token})
    }
  }

  verifyCode = (values) => {
    this.setState({loading: true})
    userService.verifyResetPasswordToken(values)
    .then(code => this.setState({
      token: code,
      success: true,
      loading: false
    }))
    .catch(e => {
      this.setState({
        error: e,
        success: false,
        loading: false
      })
    })
  }

  renderPasswordVerificationError() {
    const { error } = this.state;
    const { language } = this.props;
    return (
      <FormHolder language={this.props.language}> 
        <MessageHolder language={this.props.language}>
          {error ? 
            <h2>{error.message === "Inactive Code. Please restart the process." ? language.reset_password.reset_password_page.notification.error.inactive : language.reset_password.reset_password_page.notification.success.secret_question.error1}</h2> : 
            <h2>This verification request is invalid</h2>
          }
        </MessageHolder>
      </FormHolder>
    )
  }

  renderOTPVerificationForm() {
    const { error } = this.state;
    return (
      <FormHolder message={error && error.message}>
        <VerifyOTPForm
          title={this.props.location.state.message}
          onSubmit={this.verifyCode}/>
      </FormHolder>
    )
  }
  
  render() {
    const { token, loading, success } = this.state;
    const { location, language } = this.props;
    if (loading) return <LoadingScreen />

    if (success) return <Redirect to={{
      pathname: "/reset-password/security",
      state: {
        code: token
      }
    }}/>

    if (!token && location.state) {
      return this.renderOTPVerificationForm();
    } else {
      return this.renderPasswordVerificationError();
    }
  }
}

function mapStateToProps(state) {
  const {language} = state.language
  return {language };
}

export default connect(mapStateToProps)(ResetPasswordVerify);
