import React from 'react';
import LOGO from '../../img/RH-Horizontal-Logo.svg';

const PreAuthFormHolder = (props) => {
  return (
    <div className="dashboard" style={{height: "100%"}}>
        <div className="login_form">

            <img src={LOGO} alt="RelianceHMO's Logo" />

            { props.isLogin && <p className="dashboard-card__body-text">To access to all your dashboards,<br/>
                Login with your RelianceHMO account </p>}
            
            { props.message && <div style={{padding:"10px",backgroundColor:"red",color:"white",visibility:(!props.isLoading && props.message)?"visible":"hidden"}}>
              {props.message}
              </div> }

            {props.children} 

      </div>
    </div>
  )
};

export default PreAuthFormHolder